<template>
  <div>
    <b-row>
      <b-col
        v-for="(module, moduleIndex) in mappingModulePages"
        :key="module.header"
        md="6"
      >
        <b-card style="min-height: 95%">
          <h1>
            <b-badge
              variant="primary"
              class="badge-glow"
            >
              {{ module.header }}
            </b-badge>
          </h1>
          <hr>
          <div
            v-for="(page, pageIndex) in module.children"
            :key="page.route"
          >
            <h2 class="ml-5">
              {{ page.title }}
            </h2>
            <div class="demo-inline-spacing ml-5">
              <b-form-checkbox
                v-model="mappingModulePages[moduleIndex].children[pageIndex].permissions.read"
                class="custom-control-primary"
              >
                Voir
              </b-form-checkbox>
              <b-form-checkbox
                v-model="mappingModulePages[moduleIndex].children[pageIndex].permissions.write"
                class="custom-control-info"
              >
                Modification
              </b-form-checkbox>
              <b-form-checkbox
                v-model="mappingModulePages[moduleIndex].children[pageIndex].permissions.all"
                class="custom-control-danger"
              >
                Suppression
              </b-form-checkbox>
            </div>
            <hr>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-button
      variant="gradient-primary"
      @click="savePermissions"
    >
      Enregistrer les permissions
    </b-button>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCol, BFormCheckbox, BRow,
} from 'bootstrap-vue'
import { findPagePermissions, isUserLoggedIn } from '@/auth/utils'
import { API_URL } from '@/helpers/global-scops'
import showToast, { myLogout } from '@/helpers/methodes'

export default {
  name: 'MainVue',
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormCheckbox,
    BCard,
  },
  data() {
    return {
      mappingModulePages: [
        {
          header: 'Tableaux-de-bord-stocks',
          children: [
            {
              title: 'Tableaux-de-bord-stocks',
              route: 'tableaux-de-bord-stocks',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tableaux de bord - Logs',
              route: 'tableaux-de-bord-logs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tableaux de bord - Suivi stock distributeurs',
              route: 'tableaux-de-bord-suivi-stock-distributeurs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tableaux de bord - Commerciaux',
              route: 'tableaux-de-bord-commerciaux',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tableaux de bord - Produits',
              route: 'tableaux-de-bord-produits',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'RegiomappingModulePagesns',
          children: [
            {
              title: 'Liste des regions',
              route: 'regions-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Region details',
              route: 'regions-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Zones',
          children: [
            {
              title: 'Zones',
              route: 'zones-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Zones details',
              route: 'zones-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Commerciaux',
          children: [
            {
              title: 'Liste des commerciaux',
              route: 'commerciaux-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Les objectifs commerciaux',
              route: 'commerciaux-objectifs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Les statistiques commerciaux',
              route: 'commerciaux-statistiques',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Commerciaux - Details',
              route: 'commerciaux-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Commerciaux - Visite PDV',
              route: 'commerciaux-visite-pdv',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            // {
            //   title: 'Commerciaux - Visite Distributeur',
            //   route: 'commerciaux-visite-distributeur',
            //   permissions: {
            //     read: false,
            //     write: false,
            //     all: false,
            //   },
            // },
          ],
        },
        {
          header: 'Distributeurs',
          children: [
            {
              title: 'Distributeurs - Liste',
              route: 'distributeurs-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Distributeur details',
              route: 'distributeurs-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Distributeurs - Visites',
              route: 'distributeurs-visites',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Distributeurs - Statistiques',
              route: 'distributeurs-statistiques',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Distributeurs - Objectifs',
              route: 'distributeurs-objectifs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Tournées commerciale',
          children: [
            {
              title: 'Liste des tournées',
              route: 'tourners-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Statistiques',
              route: 'tourners-statistiques',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tournée details',
              route: 'tourners-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Tournée  Visite details',
              route: 'tourners-visite-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Points de vente',
          children: [
            {
              title: 'Liste',
              route: 'points-de-vente-liste',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'PDV-Type',
              route: 'parametres-pdv-type',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Point de vente - Details',
              route: 'points-de-vente-details',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Points de vente - Statistiques',
              route: 'points-de-vente-statistiques',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'PDVs - Visites',
              route: 'pdvs-visites',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Géolocalisations',
          children: [
            {
              title: 'Carte',
              route: 'geolocalisations-carte',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Recherches',
              route: 'geolocalisations-recherche',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Géolocalisations - Tracking',
              route: 'geolocalisations-tracking',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
        {
          header: 'Paramètres',
          children: [
            {
              title: 'Canaux de distribution',
              route: 'parametres-canaux-distribution',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Produits-Type',
              route: 'parametres-produits-type',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Produit-Saveurs',
              route: 'parametres-produit-saveurs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Produits-Parfum',
              route: 'parametres-produits-parfum',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Produits-Afridia',
              route: 'parametres-produits-afridia',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Produits-Concurrence',
              route: 'parametres-produits-concurrence',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Arrondissement',
              route: 'parametres-arrondissement',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Profiles',
              route: 'parametres-profiles',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Utilisateurs',
              route: 'parametres-utilisateurs',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Paramètres - Configurations',
              route: 'parametres-configurations',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
            {
              title: 'Profile',
              route: 'parametres-profile',
              permissions: {
                read: false,
                write: false,
                all: false,
              },
            },
          ],
        },
      ],
    }
  },
  created() {
    this.getPermissions()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    savePermissions() {
      if (!isUserLoggedIn()) return myLogout(this)
      // Enregistrez vos permissions ici.
      // Vous pouvez les envoyer à un serveur, les enregistrer dans un cookie, etc.
      console.log(this.mappingModulePages)
      this.$http.post(`${API_URL}save-permissions/`, {
        profile_id: this.$route.params.id,
        permissions: this.mappingModulePages,
      })
        .then(() => {
          this.getPermissions()
          showToast(this, 'primary', 'Permissions enregistrées avec succès')
        })
        .catch(error => {
          console.log(error)
        })
    },
    getPermissions() {
      // Récupérez vos permissions ici.
      // Vous pouvez les récupérer d'un serveur, d'un cookie, etc.
      this.$http.get(`${API_URL}pages-permissions/?profile_id=${this.$route.params.id}`)
        .then(response => {
          console.log(response.data)
          this.mappingModulePages.forEach(module => {
            module.children.forEach(page => {
              console.log(page, page.route)
              // eslint-disable-next-line no-param-reassign
              page.permissions = findPagePermissions(page.route, response.data)
              console.log(page.permissions)
            })
          })
          // this.mappingModulePages = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
